import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { H3, H4 } from "../../components/atoms/headings";
import { ExternalBlogPostLink, HoverLink } from "../../components/atoms/links";
import { P } from "../../components/atoms/paragraph";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  @media (min-width: 760px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Card = styled.div`
  background-color: #fafafa;
  padding: 2rem;
  display: grid;
  place-items: center;
  box-shadow: 0 2px 10px #e0e0e0;
  border-radius: 7px;
  min-height: 500px;
  height: 100%;
  @media (min-width: 760px) {
    padding: 3.75rem;
  }
`;

interface RecommendationGroupProps {}

const RecommendationGroup = ({ slice }) => {
    // console.log(slice.items);
    // const ref = React.useRef(null);

//     const handleClick = (key) =>
//   ref[key].current.scrollIntoView({
//     behavior: "smooth",
//     block: "start",
//   });

  return (
      <>


  <div style={{marginBottom: "2.5rem"}}>
  <H3 style={{fontSize: "1.75rem", marginBottom: ".5rem"}}>{slice.primary.group_title.text}</H3>
  <div style={{width: "100%", height: "1px", backgroundColor: "grey", marginBottom: "4rem"}}></div>
  <Container>
  {slice.items.map(ele => (
      <Card>
          <div>
      <H4>{ele.product_title.text}</H4>
      <P>{ele.product_body.text}</P>
      <HoverLink link={ele.hyperlink.url} />
      </div>
      </Card>
  ))}
  </Container>
  </div>
  </>);
};

export default RecommendationGroup;

export const query = graphql`
  fragment RecommendationGroups on PrismicRecommendationPageDataBody1RecommendationSlice {
    primary {
      group_title {
        text
      }
    }
    items {
      product_title {
        text
      }
      product_body {
        text
      }
      hyperlink {
        url
      }
    }
  }
`;
