import { SliceZone } from "@prismicio/react";
import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { H1, H2 } from "../components/atoms/headings";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import { recPageComponents } from "../slices/recommendations";

// styles

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 8rem;
  max-width: 1280px;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 20rem;
  @media (min-width: 1280px) {
    gap: 2.5rem;
  }
`;

const Container = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 1280px) {
    gap: 2.5rem;
  }
`;

interface RecommendationsProps {}

const Recommendations = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicRecommendationPage;
  const { lang, type, url } = doc || {};
  const alternateLanguages = doc.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  return (
    <Layout activeDocMeta={activeDoc}>
      <SEO
        lang={doc.lang}
        title={`${doc.data.body.map((ele) => ele.primary.seo_title)}`}
        description={doc.data.body.map((ele) => ele.primary.meta_description)}
        type="website"
      />

      <Wrapper>
        <H1 style={{ textAlign: "center", marginBottom: "2rem" }}>{doc.data.title.text}</H1>
        <H2 style={{ textAlign: "center", fontWeight: "500" }}>{doc.data.subtitle.text}</H2>
        <Container>
          <SliceZone slices={doc.data.body1} components={recPageComponents} />
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default Recommendations;

export const query = graphql`
  query RecommendationsQuery($id: String, $lang: String) {
    prismicRecommendationPage(id: { eq: $id }, lang: { eq: $lang }) {
      url
      type
      lang
      last_publication_date
      alternate_languages {
        uid
        type
        raw
        lang
        id
      }
      data {
        title {
          text
        }
        subtitle {
          text
        }
        body {
          ... on PrismicRecommendationPageDataBodyMetaTags {
            id
            primary {
              seo_title
              meta_description
            }
          }
        }
        body1 {
          ... on PrismicRecommendationPageDataBody1RecommendationSlice {
            slice_type
          }
          ...RecommendationGroups
        }
      }
    }
  }
`;
